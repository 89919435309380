<template>
  <div class="page-sub-box sub_new_style01 sub_ui_box1">
    <!--"sub_new_style01" class적용시 상단라인 삭제-->
    <!-- 검색영역 -->
    <div class="page_search_box border0 mar_b0">
      <div class="inner clearfix">
        <table class="line-bin">
          <colgroup>
            <col style="width: 70px" />
            <col style="width: 150px" />
            <col style="width: 240px" />
            <col style="width: auto" />
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">
                <label for="label01"> 조회 기준 </label>
              </th>
              <td>
                <DxRadioGroup
                  class="check-type col"
                  :items="config.reportType.items"
                  :value="getReportType"
                  :layout="config.reportType.layout"
                  @value-changed="onChangedReportType"
                />
              </td>
              <td>
                <div v-show="isMonthSelected">
                  <DxSelectBox
                    v-model="searchYear"
                    :data-source="config.periodYear"
                    display-expr="name"
                    value-expr="value"
                    :styling-mode="stylingMode"
                    width="123px"
                    :height="30"
                  />
                  <span class="px-5"></span>
                  <DxSelectBox
                    v-model="searchMonth"
                    :data-source="config.periodMonth"
                    display-expr="name"
                    value-expr="value"
                    :styling-mode="stylingMode"
                    width="100px"
                    :height="30"
                  />
                </div>
                <div v-show="!isMonthSelected" class="ui-datepicker period fl mar_ri10">
                  <div class="ui-datepicker-item">
                    <DxDateBox
                      :edit-enabled="false"
                      :styling-mode="stylingMode"
                      :width="120"
                      v-model="searchDate"
                      type="date"
                      display-format="yyyy.MM.dd"
                      :height="34"
                    />
                  </div>
                </div>
              </td>
              <td>
                <DxButton text="검색" class="btn_M box-btn-search" type="button" :height="34" @click="onSearch" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- 검색영역 -->

    <!-- 예측 결과 그래프 영역 -->
    <h2 class="h2-title-style">예측 결과</h2>

    <div class="chart-style">
      <DxChart
        width="100%"
        id="chart1"
        :sticky-hovering="false"
        :data-source="this.dataGrid1.dataSource"
        :customize-point="customizePoint"
        @legend-click="onLegendClick"
      >
        <DxCommonSeriesSettings argument-field="ymd" type="line">
          <DxPoint hover-mode="allArgumentPoints" :size="8" />
        </DxCommonSeriesSettings>
        <DxArgumentAxis :visual-range="initialRange1">
          <DxLabel :customize-text="customizeArgumentText" />
        </DxArgumentAxis>
        <DxValueAxis :tick-interval="2000">
          <DxLabel format="fixedPoint" />
        </DxValueAxis>
        <DxSeries value-field="offer1" name="최신 예측값" color="#0163a0" />
        <DxSeries value-field="offer3" name="수정 예측값" color="#feba3b" />
        <DxSeries value-field="offer4" name="실제값" color="#00ffd5" />
        <DxSeries name="기준값" color="#ff455c" />
        <DxScrollBar :visible="true" />
        <DxZoomAndPan argument-axis="both" />
        <DxLegend
          position="outside"
          vertical-alignment="bottom"
          horizontal-alignment="right"
          :row-item-spacing="1"
          :customizeItems="customizeLegendItems"
        />
        <DxExport :enabled="false" />
        <DxTooltip :enabled="true" :customize-tooltip="customizeTooltip" />
      </DxChart>
    </div>
    <!-- 예측 결과 그래프 영역-->

    <!-- 예측 결과 상세 영역 -->
    <h2 class="h2-title-style">예측 결과 상세</h2>

    <table class="table_list pad_le10">
      <colgroup>
        <col style="width: 600px; min-width: 600px" />
        <col style="width: 300px; min-width: 300px" />
        <col style="" />
      </colgroup>
      <tr>
        <td>
          <div class="fl">
            <esp-dx-data-grid :data-grid="dataGrid1" ref="dataGrid1" @row-click="onRowClick" @saving="onSaving" />
          </div>
        </td>
        <td>
          <div class="fl pad_le30">
            <DxDataGrid :data-grid="dataGrid2" ref="dataGrid2" />
          </div>
        </td>
        <td>
          <div class="pad_le20">
            <DxChart
              id="chart2"
              :sticky-hovering="false"
              :data-source="this.dataGrid2.dataSource"
              @pointClick="onPointClick"
              @legend-click="onLegendClick"
            >
              <DxCommonSeriesSettings
                argument-field="ymd"
                type="line"
                hover-mode="allArgumentPoints"
                selection-mode="allArgumentPoints"
                horizontal-offset="10000"
                :horizontal-offset="10000"
              >
                <DxPoint hover-mode="allArgumentPoints" :size="8" />
              </DxCommonSeriesSettings>
              <DxArgumentAxis :visual-range="initialRange2">
                <DxLabel :customize-text="customizeArgumentText2" />
              </DxArgumentAxis>
              <DxValueAxis :tick-interval="2000">
                <DxLabel format="fixedPoint" />
              </DxValueAxis>
              <DxSeries value-field="offer2" name="예측값" color="#0163a0" />
              <DxSeries value-field="offer1" name="실제값" color="#00ffd5" />
              <DxScrollBar :visible="true" />
              <DxZoomAndPan argument-axis="both" />
              <DxLegend
                position="outside"
                vertical-alignment="bottom"
                horizontal-alignment="right"
                :row-item-spacing="1"
                :customizeItems="customizeLegendItems"
              />
              <DxExport :enabled="false" />
              <DxTooltip :enabled="true" :customize-tooltip="customizeTooltip2" />
            </DxChart>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
  import Query from 'devextreme/data/query';

  import {
    DxChart,
    DxSeries,
    DxCommonSeriesSettings,
    DxPoint,
    DxLabel,
    DxArgumentAxis,
    DxValueAxis,
    DxFormat,
    DxScrollBar,
    DxZoomAndPan,
    DxLegend,
    DxExport,
    DxTooltip,
  } from 'devextreme-vue/chart';
  import { DxToolbarItem } from 'devextreme-vue/popup';
  import DxDateBox from 'devextreme-vue/date-box';
  import DxButton from 'devextreme-vue/button';
  import DxRadioGroup from 'devextreme-vue/radio-group';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import moment from 'moment/moment';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: {
      EspDxDataGrid,
      DxSelectBox,
      DxChart,
      DxSeries,
      DxCommonSeriesSettings,
      DxPoint,
      DxLabel,
      DxArgumentAxis,
      DxValueAxis,
      DxFormat,
      DxScrollBar,
      DxZoomAndPan,
      DxLegend,
      DxExport,
      DxDateBox,
      DxButton,
      DxTooltip,
      DxRadioGroup,
      DxToolbarItem,
    },
    data() {
      return {
        config: {
          periodYear: [],
          periodMonth: [],
          reportType: {
            layout: 'horizontal',
            items: [
              { id: 0, text: '월별' },
              { id: 1, text: '일별' },
            ],
            value: 0,
          },
        },
        stylingMode: 'outlined', //outlined, underlined, filled
        dataGrid1: {
          refName: 'dataGrid1',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: true, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'500',     // 주석처리시 100%
          height: '300', // 주석처리시 100%
          apiActionNm: {},
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            optionChanged: true,
            rowClick: true,
            saving: true,
          },
          showActionButtons: {
            select: false,
            copy: false,
            delete: false,
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: false, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: false, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },

          editing: {
            allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowDeleting: false,
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'none', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '날짜',
              dataField: 'ymd',
              width: 100,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              allowSorting: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
              calculateCellValue: this.customizeColumnText,
            },
            {
              caption: '최신 예측값',
              dataField: 'offer1',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowSorting: true,
            },
            {
              caption: '최초 예측값',
              dataField: 'offer2',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowSorting: true,
            },
            {
              caption: '수정 예측값',
              dataField: 'offer3',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              allowSorting: true,
            },
            {
              caption: '실제값',
              dataField: 'offer4',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowSorting: true,
            },
          ],
        },
        dataGrid2: {
          refName: 'dataGrid2',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: true, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'300',     // 주석처리시 100%
          height: '300', // 주석처리시 100%
          apiActionNm: {},
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            optionChanged: true,
          },
          showActionButtons: {
            select: false,
            copy: false,
            delete: false,
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: false, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: false, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },

          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowDeleting: false,
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'none', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '예측 날짜',
              dataField: 'ymd',
              width: 150,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              allowSorting: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
              calculateCellValue: this.customizeColumnText2,
            },
            {
              caption: '예측값',
              dataField: 'offer2',
              width: 60,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              allowSorting: true,
            },
          ],
        },
        isMonthSelected: true,
        isMonthSearched: true,
        searchDate: this.currentByFormat('YYYY-MM-DD'),
        searchMonth: this.currentByFormat('MM'),
        searchYear: this.currentByFormat('YYYY'),
        initialRange1: [],
        initialRange2: [],
      };
    },
    computed: {
      getReportType() {
        return this.config.reportType.items.find(d => d.id === this.config.reportType.value);
      },
      getReportTypeId() {
        return this.getReportType.id;
      },
    },
    methods: {
      /** @description: 조회구분 라디오 버튼 클릭 이벤트 */
      onChangedReportType(e) {
        this.$set(this.config.reportType, 'value', e.value.id);
        if (this.getReportTypeId === 0) {
          this.isMonthSelected = true;
        } else {
          this.isMonthSelected = false;
        }
      },
      currentByFormat(format) {
        return moment().format(format);
      },
      onSearch() {
        this.selectListData();
      },
      onPointClick({ target }) {
        target.select();
      },
      setPriodMonthAndYear() {
        const months = new Array(12).fill().map((v, i) => {
          const month = i + 1;
          const value = month < 10 ? `0${month}` : month.toString();
          return { name: `${month}월`, value };
        });

        const years = [];
        const ersInitYear = this.$_getReportStartDt.substring(0, 4);
        let year = moment().format('YYYY');
        while (ersInitYear <= year) {
          years.push({ name: year + '년', value: year });
          year--;
        }
        this.config.periodMonth = months;
        this.config.periodYear = years;
      },
      getTooltipForm(pointInfo, formatDtF) {
        return {
          html: `
        <div id="forecast-report-tooltip">
            <div class='forecast-report-tooltip-header'>${formatDtF(pointInfo.argumentText)}</div>
            <div class='forecast-report-tooltip-body'>
                <div class='series-name'><span class='top-series-name'>${pointInfo.seriesName}</span>: </div>
                <div class='value-text'><span class='top-series-value'>${pointInfo.valueText
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span></div>
            </div>
        </div>
        `,
        };
      },
      customizeTooltip(pointInfo) {
        return this.getTooltipForm(pointInfo, this.formatDt);
      },
      customizeTooltip2(pointInfo) {
        return this.getTooltipForm(pointInfo, this.formatDt2);
      },
      customizePoint(data) {
        if (
          (this.isMonthSearched && data.argument === this.searchYear + '' + this.searchMonth + '01') ||
          (!this.isMonthSearched && data.argument === this.$_commonlib.formatDate(this.searchDate, 'YYYY-MM-DD', 'YYYYMMDD'))
        ) {
          return { color: '#ff455c', hoverStyle: { color: '#ff455c' } };
        }
        return null;
      },
      onLegendClick({ target: series }) {
        if (series.isVisible()) {
          series.hide();
        } else {
          series.show();
        }
      },
      onRowClick(row) {
        this.dataGrid2.dataSource = row.data.data;
        this.initialRange2 = [row.data.data[row.data.data.length - 7].ymd, row.data.data[row.data.data.length - 1].ymd];
      },
      formatDt(date) {
        let format = 'YYYY.MM.DD';
        if (this.isMonthSearched) {
          format = 'YYYY.MM';
        }
        return this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmssSSS', format);
      },
      formatDt2(date) {
        return this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD');
      },
      customizeArgumentText(e) {
        return this.formatDt(e.value);
      },
      customizeArgumentText2(e) {
        return this.formatDt2(e.value);
      },
      customizeLegendItems(items) {
        items.forEach(item => {
          item.marker.size = 8;
        });
      },
      customizeColumnText(rowData) {
        return this.formatDt(rowData.ymd);
      },
      customizeColumnText2(rowData) {
        return this.formatDt2(rowData.ymd);
      },
      getFormatDate(date) {
        let year = date.getFullYear(); //yyyy
        let month = 1 + date.getMonth(); //M
        month = month >= 10 ? month : '0' + month; //month 두자리로 저장
        let day = date.getDate(); //d
        day = day >= 10 ? day : '0' + day; //day 두자리로 저장
        return year + '' + month + '' + day; //'-' 추가하여 yyyy-mm-dd 형태 생성 가능
      },
      async onSaving(e) {
        this.$_Toast('저장 기능 구현 예정', '메세지');
        return false;
      },
      creatData() {
        this.selectListData();
      },
      async selectListData() {
        this.isMonthSearched = this.isMonthSelected;

        this.dataGrid2.dataSource = [];

        let searchDate;
        if (this.isMonthSearched) {
          searchDate = this.searchYear + '' + this.searchMonth;
        } else {
          searchDate = this.$_commonlib.formatDate(this.searchDate, 'YYYY-MM-DD', 'YYYYMMDD');
        }

        let paramsData = {
          isMonthSearched: this.isMonthSearched,
          searchDate: searchDate,
        };

        let payload = {
          actionname: 'FORECAST_LIST_ALL',
          data: { params: paramsData },
          loading: true,
        };

        // let res = await this.CALL_API(payload);
        let res = await { data: { data: [], header: { totalCount: 0, resCode: 'success' } }, status: 200 };

        if (res.status === 200) {
          if (res.data.header.resCode === 'success') {
            /////////// 임시로 데이터 넣음. 실데이터 받아오는로직으로 변경해야함 //////////////
            let startDate;
            let endDate;
            if (this.isMonthSearched) {
              if (this.searchMonth < 9) {
                startDate = new Date(Number(this.searchYear) - 1, 0, 1);
                endDate = new Date(Number(this.searchYear), 11, 1);
              } else {
                startDate = new Date(Number(this.searchYear), 0, 1);
                endDate = new Date(Number(this.searchYear) + 1, 11, 1);
              }
              let fixOffer1 = Math.floor(Math.random() * (100000 - 50000) + 50000);
              for (let i = 0; endDate >= startDate; i++) {
                res.data.data[i] = {
                  ymd: this.getFormatDate(startDate),
                  offer1: Math.floor(Math.random() * (100000 - 50000) + 50000),
                  offer2: Math.floor(Math.random() * (100000 - 50000) + 50000),
                  offer3: Math.floor(Math.random() * (100000 - 50000) + 50000),
                  offer4: fixOffer1,
                  data: [],
                };
                const endDate2 = new Date(startDate);
                endDate2.setDate(endDate2.getDate() - 30);
                let fixOffer2 = Math.floor(Math.random() * (100000 - 50000) + 50000);
                for (let j = 0; endDate2 < startDate; j++) {
                  res.data.data[i].data[j] = {
                    ymd: this.getFormatDate(endDate2),
                    offer1: fixOffer2,
                    offer2: Math.floor(Math.random() * (100000 - 50000) + 50000),
                  };
                  endDate2.setDate(endDate2.getDate() + 1);
                }
                startDate.setMonth(startDate.getMonth() + 1);
              }
              this.initialRange1 = [res.data.data[0].ymd, res.data.data[23].ymd];
            } else {
              var year = searchDate.substring(0, 4);
              var month = searchDate.substring(4, 6);
              var date = searchDate.substring(6, 8);
              startDate = new Date(Number(year), Number(month) - 1, Number(date));
              startDate.setDate(startDate.getDate() - 7);

              endDate = new Date(Number(year), Number(month) - 1, Number(date));
              endDate.setDate(endDate.getDate() + 45);

              let fixOffer1 = Math.floor(Math.random() * (100000 - 50000) + 50000);
              for (let i = 0; endDate > startDate; i++) {
                res.data.data[i] = {
                  ymd: this.getFormatDate(startDate),
                  offer1: Math.floor(Math.random() * (100000 - 50000) + 50000),
                  offer2: Math.floor(Math.random() * (100000 - 50000) + 50000),
                  offer3: Math.floor(Math.random() * (100000 - 50000) + 50000),
                  offer4: fixOffer1,
                  data: [],
                };
                const endDate2 = new Date(startDate);
                endDate2.setDate(endDate2.getDate() - 30);
                let fixOffer2 = Math.floor(Math.random() * (100000 - 50000) + 50000);
                for (let j = 0; endDate2 < startDate; j++) {
                  res.data.data[i].data[j] = {
                    ymd: this.getFormatDate(endDate2),
                    offer1: fixOffer2,
                    offer2: Math.floor(Math.random() * (100000 - 50000) + 50000),
                  };
                  endDate2.setDate(endDate2.getDate() + 1);
                }
                startDate.setDate(startDate.getDate() + 1);
              }
              this.initialRange1 = [res.data.data[0].ymd, res.data.data[20].ymd];
            }
            res.data.header.totalCount = res.data.data.length;
            /////////// 임시로 데이터 넣음. 실데이터 받아오는로직으로 변경해야함 //////////////
            this.dataGrid1.dataSource = res.data.data;
          }
        }
      },
    },
    created() {
      this.creatData();
    },
    mounted() {
      this.setPriodMonthAndYear();
      const cssRule = `div.content:has(.page-sub-box[${this.$vnode.elm.attributes[0].name}]) { min-width: 1600px; }`;
      const styleElement = document.createElement('style');
      const cssText = document.createTextNode(cssRule);
      styleElement.appendChild(cssText);
      document.head.appendChild(styleElement);

      // Legend 위치 가운데정렬을 API에서 지원하지 않아 CSS로 강제로 지정해보려 했으나 rowClick등의 이벤트가 발생되면 초기화되어서 못씀..
      /*const targetElement = document.querySelector(`div[${this.$vnode.elm.attributes[0].name}][id="chart2"]`);
    const dxcLegendElement = targetElement.querySelector('.dxc-legend');
    const childElement = dxcLegendElement.firstElementChild;
    childElement.setAttribute('transform', 'translate(549, 95.5)');*/
    },
  };
</script>

<style>
  .dxc-tooltip:has(#forecast-report-tooltip) {
    z-index: 3;
  }

  .forecast-report-tooltip-header {
    margin-bottom: 5px;
    font-size: 13px;
    font-weight: 500;
    padding-bottom: 5px;
    border-bottom: 1px solid #c5c5c5;
  }

  .forecast-report-tooltip-body {
    width: 130px;
  }

  .forecast-report-tooltip-body .series-name {
    font-weight: normal;
    opacity: 0.6;
    display: inline-block;
    line-height: 1.5;
    padding-right: 10px;
    width: 86px;
  }

  .forecast-report-tooltip-body .value-text {
    display: inline-block;
    line-height: 1.5;
    width: 30px;
  }
</style>

<!-- content div min-width 지정을 위해 필요 -->
<style scoped>
  .page-sub-box.sub_new_style01.sub_ui_box1 {
    padding-bottom: 0 !important;
  }

  .h2-title-style {
    font-size: 18px;
    color: #545454;
    font-weight: 500;
    border-top: 1px solid #ebebeb;
    padding: 20px 0 10px 3px;
  }

  .chart-style {
    padding: 10px 0 0 10px;
  }

  .mar_b0 {
    margin-bottom: 0;
  }

  .px-5 {
    padding: 0 5px;
  }

  #chart1 {
    width: 1520px;
    height: 260px;
    margin-bottom: 20px;
  }

  #chart2 {
    width: 610px;
    height: 230px;
    margin-top: 70px;
  }
</style>
